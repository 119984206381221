import { Pipe, PipeTransform } from '@angular/core';
import { AnnouncementEnum, RoleId } from 'src/app/models/enums/enumConstant';

@Pipe({
    name: 'announcementReceiver'
})
export class announcementReceiverCheckPipe implements PipeTransform {

    transform(roles: number): string {
        let roleString = '';
        if (roles === null || roles === 0) return roleString = 'All';

        for (let [key, value] of Object.entries(AnnouncementEnum)) {
            if (parseInt(key) === roles) {
                roleString = value as string;
            }
        }

        return roleString;
    }

}
