import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./routes/main/main.module').then(m => m.MainModule)
  },
  {
    path: 'sm-logged',
    canActivate: [AuthGuard],
    component: ContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'setting',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'feedback',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/feedback/feedback.module').then(m => m.FeedbackModule)
      },

    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./routes/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./routes/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./routes/change-password/change-password.module').then(m => m.ChangePasswordModule)
  },
  {
    path: 'certificate',
    loadChildren: () => import('./routes/certificate/certificate.module').then(m => m.CertificateModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
