import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Class } from '../models/class';
import { BaseCrudService } from './base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ClassService extends BaseCrudService<Class> {
  
  classBehavior: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(injector: Injector) {
    super(injector);
    this.path = '/student/class';
  }

  setClasses (id: string) {
    this.classBehavior.next(id)
  }

  getClasses() {
    return this.classBehavior.asObservable()
  }
}
