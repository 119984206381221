import { Pipe, PipeTransform } from '@angular/core';
import { Role, RoleId } from 'src/app/models/enums/enumConstant';

@Pipe({
  name: 'staffRole'
})
export class StaffRolePipe implements PipeTransform {
  transform(role: number): string {
    let roleString: string = '';

    if (!role) return '';
    switch (role) {
      case RoleId.ADMIN:
        roleString = Role.ADMIN;
        break;
      case RoleId.SCHOOL:
        roleString = Role.SCHOOL;
        break;
      case RoleId.TEACHER:
        roleString = Role.TEACHER;
        break;
      case RoleId.FACULTY:
        roleString = Role.FACULTY;
        break;
      case RoleId.DEPARTMENT:
        roleString = Role.DEPARTMENT;
        break;
      case RoleId.STUDENT:
        roleString = Role.STUDENT;
        break;
      default:
        roleString = roleString;
        break;
    }
    return roleString;
  }
  // transform(name: string): string {
  //   let role: string = '';
  //   if (name) {
  //     role = name.toLowerCase();
  //   }
  //   let result: string = '';
  //   switch (role) {
  //     case 'teacher':
  //       result = 'គ្រូបង្រៀន';
  //       break;
  //     case 'homeroom_teacher':
  //       result = 'គ្រូបង្រៀនបន្ទុក';
  //       break;
  //     case 'school':
  //       result = 'រដ្ឋបាលសាលា';
  //       break;
  //     case 'reviewer':
  //       result = 'អ្នកត្រួតពិនិត្យ';
  //       break;
  //     case 'officer':
  //       result = 'ការិយាល័យ';
  //       break;
  //     default:
  //       result = name;
  //       break;
  //   }
  //   return result;
  // }
}
