<div class="app-menu" (window:resize)="onResize()">
  <mat-sidenav-container class="app-container" autosize>
    <mat-sidenav
      *ngIf="mobileQuery"
      #sidenav
      class="menu-sidenav"
      [class.large-width]="mobileQuery"
      [mode]="mobileQuery ? 'over' : 'side'"
      [opened]="!mobileQuery"
      [disableClose]="!mobileQuery"
    >
      <div class="main-logo" routerLink="/">
        <img
          src="{{account?.schools?.profile_image ? (account?.schools?.profile_image | staticFile) + '?image_size=m' : 'assets/imgs/school-profile.svg' }}"
          width="68"
          height="68"
          alt="logo-image"
        />
      </div>
    </mat-sidenav>
    <mat-sidenav-content
      [class.explicit-sm-ml]="!mobileQuery && !isExpanded"
      [class.explicit-lg-ml]=" !mobileQuery  && isExpanded"
      id="sidenav-content"
    >
      <div
        class="menu-sidenav-content app-root-content"
        [class.nav-text-hidden]="!isExpanded"
        [class.nav-text-show]="isExpanded"
      >
        <mat-toolbar class="dashboard-toolbar">
          <header class="dashboard__header">
            <div class="menu-container">
              <button  *ngIf="mobileQuery" class="icon " mat-icon-button (click)="toggleSideNav()">
                <mat-icon svgIcon="menu-hamburger"></mat-icon>
              </button>
              <img class="img"
                [src]="'assets/imgs/logo1.png'"
                alt="logo-image"
              />
              <span class="menu-title" routerLink="home">TVET SMS</span>
            </div>
            <h1 class="home">
              <div class="flex-header" *ngIf="selectedYearItem?.classes">
                <mat-select *ngIf="!mobileQuery" [(value)]="selectedYearItem" (selectionChange)="onAcademicChange()"
                  class="academic-filter">
                  <mat-select-trigger>
                    <span>ថ្នាក់សិក្សា ៖</span>
                    {{ selectedYearItem?.classes?.name }}
                  </mat-select-trigger>
                  <mat-option *ngFor="let year of academicYears" [value]="year">{{ year?.classes?.name }}</mat-option>
                </mat-select>
              </div>
              <div class="mobile-filter">
                <mat-select *ngIf="mobileQuery" [(value)]="selectedYearItem" (selectionChange)="onAcademicChange()" class="academic-filter">
                  <mat-select-trigger>
                    <span>ថ្នាក់សិក្សា ៖</span>
                    {{ selectedYearItem?.classes.name }}
                  </mat-select-trigger>
                  <mat-option *ngFor="let year of academicYears" [value]="year">{{ year.classes.name }}</mat-option>
                </mat-select>
              </div>
            </h1>

            <div class="header-action">
              <!-- languages -->
              <button
                class="languages"
                mat-button
                [matMenuTriggerFor]="dropMenu"
                aria-label="Example icon-button with a menu"
              >
                <div class="flex items-center gap-x-4">
                  <mat-icon svgIcon="{{browserLang === 'en' ? 'english_flag' : 'khmer_flag'}}"></mat-icon>
                  <mat-icon iconPositionEnd>expand_more</mat-icon>
                </div>
              </button>
              <mat-menu #dropMenu="matMenu">
                <div>
                  <button
                    mat-menu-item
                    *ngFor="let lang of langs | keyvalue: originalOrder"
                    (click)="useLanguage(lang.key)"
                  >
                    <mat-icon svgIcon="{{lang.key === 'en' ? 'english_flag' : 'khmer_flag'}}"></mat-icon>
                    <span class="mr-8">{{ lang.value }}</span>
                    <mat-icon *ngIf="browserLang === lang.key" svgIcon="check"></mat-icon>
                  </button>
                </div>
              </mat-menu>
              <button mat-icon-button>
                <mat-icon svgIcon="notification"></mat-icon>
              </button>
              <div class="avtar">
                <button mat-button [matMenuTriggerFor]="beforeMenu" class="profile-menu-btn">
                  <div class="profile-info">
                    <div class="name"> {{(account | staffFullName) || 'Unknown'}}</div>
                    <div class="role">{{account?.roles | staffRole}}</div>
                  </div>
                  <img
                    mat-card-avatar
                    class="avtar-icon"
                    alt="avatar"
                    [src]="account?.profile_image
                    ? (account?.profile_image | staticFile) + '?image_size=s'
                    :  (account?.gender === 'female')
                      ? '/assets/imgs/female.svg'
                      : '/assets/imgs/male.svg'
                    "
                  />
                  <!-- <mat-icon>expand_more</mat-icon> -->
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before" backdropClass="hover-profile">
                  <div class="menu-profile" [routerLink]="['/sm-logged/setting']" routerLinkActive="router-link-active">
                    <button mat-menu-item>
                      <mat-icon>settings</mat-icon>{{'setting.name' | translate}}
                    </button>
                  </div>
                  <mat-divider></mat-divider>
                  <button mat-menu-item [routerLink]="['/sm-logged/feedback']">
                    <mat-icon svgIcon="feedback_fill"></mat-icon>
                    Feedback
                  </button>
                  <button mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>{{'button.logout'| translate}}
                  </button>
                </mat-menu>
              </div>
            </div>
          </header>
        </mat-toolbar>
        <section class="dash-row ">
          <router-outlet></router-outlet>
        </section>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
