import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'staticFile'
})
export class StaticFilePipe implements PipeTransform {
  transform(value: string, condition?: {elearning?: boolean}): string {
    if (value && !condition?.elearning) {
      return environment.file_static_url + value;
    } else if (value && condition?.elearning) return environment.e_learning_file_url + value;
    return null;
  }
}
