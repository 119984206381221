import { Pipe, PipeTransform } from '@angular/core';
import { AnnouncementEnum, HolidayPlatform, RoleId } from 'src/app/models/enums/enumConstant';

/**roleCheck | announcement or calendar or role by default */
@Pipe({
  name: 'roleCheck'
})
export class RoleCheckPipe implements PipeTransform {

  transform(roles: number, roleType = 'role'): string {
    let roleString = '';
    if (roles === null || roles === 0) return roleString = 'All';
    let roleShouldCheck;
    if (roleType === 'announcement') {
      roleShouldCheck = AnnouncementEnum;
    } else if (roleType === 'calendar') {
      roleShouldCheck = HolidayPlatform;
    } else {
      roleShouldCheck = RoleId;
    }

    for (let [key, value] of Object.entries(roleShouldCheck)) {
      if (parseInt(key) === roles) {
        roleString = value as string;
      }
    }

    return roleString;
  }

}
