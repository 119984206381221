import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffRolePipe } from './pipes/staff-role.pipe';
import { RoleCheckPipe } from './pipes/role-check.pipe';
import { PermissionPipe } from './pipes/permission.pipe';
import { announcementReceiverCheckPipe } from './pipes/announcement-receiver.pipe';

@NgModule({
  declarations: [StaffRolePipe, RoleCheckPipe, PermissionPipe, announcementReceiverCheckPipe],
  imports: [CommonModule],
  exports: [StaffRolePipe, RoleCheckPipe, PermissionPipe, announcementReceiverCheckPipe]
})
export class RoleModule { }
